<template>

  <v-card :loading="loaders.loading" flat>

    <v-card-title>

    </v-card-title>

    <v-card-text v-if="comicbook.id">
      <v-row>
        <v-col cols="12" lg="4" md="4">
          <image-upload-controller v-if="loaders.ready"
                                   :image="comicbook.cover_src"
                                   :url="'upload.comicbook'+(comicbook.id ? '?id='+comicbook.id : '')"
                                   :keyform="'comicbook_form[cover]'"
                                   :label="'Charger la couverture '"
                                   :notice="'Format: png, jpeg, jpg\n'+
'Dimensions: 300 x 350\n'+
'Max size: 512ko'"
                                   :maxheight="350"
                                   :maxwidth="300"
                                   :maxsize="512"
                                   @remove="()=> comicbook.cover_image = ''"
                                   @uploaded="coverUploaded"
          ></image-upload-controller>
        </v-col>
        <v-col cols="12" lg="8" md="8">

          <image-upload-controller v-if="loaders.ready"
                                   :image="comicbook.banner_src"
                                   :url="'upload.comicbook'+(comicbook.id ? '?id='+comicbook.id : '')"
                                   :keyform="'comicbook_form[banner]'"
                                   :label="'Changer la banniere '"
                                   :notice="'Format: png, jpeg, jpg\n'+
'Dimensions: 1240 x 465\n'+
'Max size: 500ko'"
                                   width=""
                                   :maxheight="465"
                                   :maxwidth="1240"
                                   :maxsize="512"
                                   @remove="()=> comicbook.banner = ''"
                                   @uploaded="bannerUploaded"
          ></image-upload-controller>

        </v-col>
      </v-row>
    </v-card-text>
    <v-card-text v-else>
      <v-alert color="warning">Vous devez enregistrer les informations general de votre serie avant
        de charger les images de couverture
      </v-alert>
    </v-card-text>
    <v-card-text>

      <h3>Detail</h3>
      <v-row>
        <v-col cols="12" md="6">
          <v-select
            :loading="loaders.categories"
            v-model="comicbook.category_id"
            outlined
            dense
            :items="categories"
            label="Categorie"
            item-value="id"
            item-text="name"
          >
            <template v-slot:selection="data">
              {{ data.item.name }}
            </template>
            <template v-slot:item="data">
              <v-list-item-content>
                {{ data.item.name }}
              </v-list-item-content>
            </template>
          </v-select>
        </v-col>
        <v-col cols="12" md="6">

          <v-combobox
            :loading="loaders.genders"
            v-model="comicbook.gender_names"
            :items="genders"
            chips dense outlined
            item-value="id"
            item-text="name"
            clearable
            label="Genere (Selectionnez 3 Max)"
            multiple
            prepend-icon="mdi-filter-variant"
            variant="solo"
          >
            <template v-slot:selection="{ attrs, item, select, selected }">
              <v-chip
                v-bind="attrs"
                :model-value="selected"
                closable
                @click="select"
                @click:close="remove(item)"
              >
                <strong>{{ item.name }}</strong>&nbsp;
                <!--                <span>(interest)</span>-->
              </v-chip>
            </template>
          </v-combobox>
          <!--          <v-select
                      :loading="loaders.genders"
                      v-model="comicbook.gender_names"

                       multiple
                      :items="genders"
                      label="Genere (Selectionnez 3 Max)"
                      item-value="name"
                      item-text="name"
                      chips
                      single-line persistent-hint
                    >
                      <template v-slot:selection="data">
                       <v-chip> {{ data.item.name }}</v-chip>
                      </template>
                      <template v-slot:item="data">
                        <v-list-item-content>
                          {{ data.item.name }}
                        </v-list-item-content>
                      </template>
                    </v-select>-->
        </v-col>
        <v-col cols="12" md="12">
          <label for="">Sens de lecture </label>
          <v-radio-group v-model="comicbook.readingdirection">
            <v-radio label="De la droite vers la gauche" :value="0"></v-radio>
            <v-radio label="De la gauche vers la droite" :value="1"></v-radio>
            <v-radio label="Du haut vers le bas" :value="2"></v-radio>
          </v-radio-group>
          <v-text-field outlined v-model="comicbook.name" label="Titre Complet"></v-text-field>
          <v-text-field outlined v-model="comicbook.alternatif"
                        label="Titre alternatif (Nom cours)"></v-text-field>
          <v-textarea outlined v-model="comicbook.synopsis" label="Synopsis"></v-textarea>
        </v-col>
        <v-col cols="12" md="6">
          <v-select
            :loading="loaders.categories"
            v-model="comicbook.status_id"
            outlined
            dense
            :items="statues"
            label="Status"
            item-value="id"
            item-text="label"
          >
            <template v-slot:selection="data">
              {{ data.item.label }}
            </template>
            <template v-slot:item="data">
              <v-list-item-content>
                {{ data.item.label }}
              </v-list-item-content>
            </template>
          </v-select>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="primary" :loading="loaders.loading" @click="save()">
        Enregistrer
      </v-btn>
      <v-btn v-if="comicbook.id" class="ml-3" color="primary" :loading="loaders.loading" @click="loaders.goto = true; save()">
        Enregistrer et voir le detail
      </v-btn>
    </v-card-actions>

  </v-card>

</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiEyeOutline,
  mdiEyeOffOutline,
} from '@mdi/js'
import {computed, ref} from '@vue/composition-api'
import {Drequest} from '@/plugins/Drequest'
import {getVuetify} from '@/plugins/utils'
import Banner from '@/components/Banner'
import {useRouter} from "@/utils";
import FollowToggleComponent from "@/components/FollowToggleComponent.vue";
import ProfileItem from "@/components/ProfileItem.vue";
import ImageUploadController from "@/components/ImageUploadController.vue";
import ChapterFormComponent from "@/components/ChapterFormComponent.vue";
// import { useMeta } from 'vue-meta'

export default {
  components: {
    ChapterFormComponent,
    ImageUploadController,
    ProfileItem, FollowToggleComponent, Banner
  },
  setup() {
    //useMeta({ title: 'Some Page' })
    const dialog = ref({
      newcomicbook: false,
      imageviewer: false,
      authentication: false,
    })

    const loaders = ref({
      config: false,
      genders: false,
      categories: false,
      loading: true,
      ready: false,
      goto: false,
    })

    const {router} = useRouter()
    const route = router.currentRoute

    const user_id = ref(localStorage.getItem('user_id'))
    const user = ref(Drequest.getUser())

    const comicbook = ref({id: parseInt(route.params.idcomic)})
    console.log(comicbook.value)
    const e1 = ref(1)
    const cover = ref(1)
    const categories = ref([])
    const genders = ref([])
    const statues = ref([])

    Drequest.api('lazyloading.tree-item?dfilters=on&tree.name:eq=category&dlang=fr')
      .get(response => {
        console.log(response)
        categories.value = response.listEntity
        loaders.value.categories = false
      })
    Drequest.api('lazyloading.tree-item?dfilters=on&tree.name:eq=gender&dlang=fr')
      .get(response => {
        console.log(response)
        genders.value = response.listEntity
        loaders.value.genders = false
      })
    Drequest.api('lazyloading.status?dfilters=on&entity.name:eq=comicbook&dlang=fr')
      .get(response => {
        console.log(response)
        statues.value = response.listEntity
        loaders.value.genders = false
      })
    if (comicbook.value.id)
      Drequest.api('detail.comicbook?id=' + comicbook.value.id).get((response) => {
        loaders.value.loading = false
        loaders.value.ready = true
        comicbook.value = response.comicbook
      });
    else {
      loaders.value.ready = true
      loaders.value.loading = false
    }

    const coverUploaded = (response) => {
      console.log(response)
      comicbook.value.cover = response.comicbook.cover
      comicbook.value.path = response.comicbook.path
    }
    const bannerUploaded = (response) => {
      console.log(response)
      comicbook.value.banner = response.comicbook.banner
      comicbook.value.path = response.comicbook.path
    }

    const save = (publish) => {

      loaders.value.loading = true
      let url;
      if (comicbook.value.id)
        url = 'update.comicbook?id=' + comicbook.value.id
      else
        url = 'comicbook.create'

      let gendersitems = [];
      for (let item of comicbook.value.gender_names)
        gendersitems.push(item.id)

      Drequest.api(url)
        .data(
          {
            comicbook: {
              'user.id': user.value.id,
              'title': comicbook.value.name,
              'readingdirection': comicbook.value.readingdirection,
              'alternatif': comicbook.value.alternatif,
              status: comicbook.value.status,
              gender_names: gendersitems.join(),
              cover: comicbook.value.cover,
              banner: comicbook.value.banner,
              path: comicbook.value.path,
              'tree_item\\category.id': comicbook.value.category_id,
              'status.id': comicbook.value.status_id,
              'synopsis': comicbook.value.synopsis //$("#postcontent").html(),
            }
          })
        .raw((response) => {

          loaders.value.loading = false
          if (response.success) {
            if (url === 'comicbook.create') {
              comicbook.value = response.comicbook
            }else if (loaders.value.goto){
              router.push("/comicbook/" + response.comicbook.nameseo + '/' + response.comicbook.id)
            } else {
              dialog.value.newcomicbook = false
            }
          }

        })

    }

    const detail = cb => {
      drawer.value = true
      comicbook.value = cb
    }
    const remove = item => {
      comicbook.value.gender_names.splice(comicbook.value.gender_names.indexOf(item), 1)
    }

    return {
      detail,
      coverUploaded,
      bannerUploaded,
      save,
      remove,

      statues,
      categories,
      genders,
      user,
      loaders,
      comicbook,
      e1,
      cover,

      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
